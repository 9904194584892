import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ToolkitSubpageLayout from "@src/layouts/ToolkitSubpageLayout";
import SEO from "@src/components/SEO";
import MarkedTranslate from "@src/components/translation/MarkedTranslate";

function GroupMaker() {
  const data = useStaticQuery(graphql`
    {
      directus {
        page_toolkit_groupmaker {
          image1 {
            filename_disk
          }
          image2 {
            filename_disk
          }
          image3 {
            filename_disk
          }
          image4 {
            filename_disk
          }
        }
      }
    }
  `);
  const {
    directus: {
      page_toolkit_groupmaker: { image1, image2, image3, image4 },
    },
  } = data;
  return (
    <ToolkitSubpageLayout page="groupmaker" screenshots={[image1, image2, image3, image4]}>
      <SEO
        title="page_titles.toolkit.groupmaker"
        description="page_descriptions.toolkit.groupmaker"
        image="https://static.classdojo.com/img/page_toolkit/toolkit_banner.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Toolkit on ClassDojo",
          description: "All your favorite teacher tools, now in the ClassDojo app :)",
          image: "https://static.classdojo.com/img/page_toolkit/toolkit_banner.jpg",
        }}
      />
      <MarkedTranslate path="directus.page_toolkit_groupmaker.text" />
    </ToolkitSubpageLayout>
  );
}

export default GroupMaker;
